.collapsable-nav {
  position: fixed;
  background: #1f1f1f;
  width: 100vw;
  height: 100vh;
  top: 0px;
  right: 0px;
  clip-path: circle(0% at calc(110vw) 0px);
  z-index: 10;
}

.nav-back-drop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.7);
  filter: blur(4px);
  z-index: 5;
}

.nav-wrapper {
  visibility: hidden
}

.visible {
  visibility: visible !important;
}

.nav-elements {
  position: absolute;
  right:150px;
  top: 0px;
}

.nav-element {
    list-style: none;
    margin: 30px 0px;
    color: white;
    text-decoration: none;
    font-size: 1.5em;
    &:hover{
      cursor: pointer;
    }

}


@media(max-width:500px){
  .nav-elements{
    right: 100px;
  }
}