@font-face {
  font-family: "White Rabbit";
  src: url("../assets/fonts/WHITRABT.woff");
}

@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@400;800&display=swap');

//variables colors
$whitish: #818181;
$greenish: #00770f;

//variable fonts
$mainFont: "White Rabbit";

.logo{
  font-family: 'Oxanium', cursive;
  font-weight: 900;
  color: $greenish;
  transform-origin: center;
  top: 50%;
  left: 50%;
  transform: scale(5);
  position: absolute;
  z-index: 5;
}

#hero {
  position: initial;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: auto;
  width: fit-content;
  height: 100vh;
  // opacity: 0;

  .social-links{
    position: absolute;
    display: flex;
    flex-direction: column;
    top:30vh;
    left:10px;

    svg{
      width: 30px;
      height: 30px;
      margin: 10px;
    }
  }
}

.content{
    // opacity: 0;
    transform: translateX(100px);
}

.white {
  color: rgb(209, 209, 209);
}

.green {
  color: $greenish;
}

.heading-hero {
  font-family: $mainFont;
  width: 35vw;
  .Typewriter__wrapper, .Typewriter__cursor{
  font-size: 1.5em !important;
}
}



.im {
  font-family: $mainFont;
  font-size: 1.8em;
  color: $whitish;
}

.call-to-action {
  background: transparent;
  color: $whitish;
  border: none;
  outline: none;
  font-family: $mainFont;
  font-size: 1.6em;
  margin-top: 50px;
  position: relative;
  padding: 10px 30px;
  transition: 1s ease-in-out;

  &:hover {
    cursor: pointer;
    color: black;
    transition: 0.5s ease-in-out;
  }

  &:hover::before {
    width: 100%;
    transition: 0.5s ease-in-out;
  }

  &::before {
    content: "";
    width: 5px;
    height: 150%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    background-color: $greenish;
    border-radius: 10px;
    z-index: -1;
    transition: 0.5s ease-in-out;
  }
}

.lines{
  .line{
    width: 2px;
    height: 0vw;
    background: #033C0B;
    position: absolute;
    transform-origin: top;
    transform:rotate(-60deg);
    z-index: -10;
    box-shadow: 0px 3px 7px #00770F;
  }
  .line:nth-child(1){
    top: 31vw;
  }
  .line:nth-child(2){
    top:16vw;
  }
  .line:nth-child(3){
    top: 12vw;
  }
}

.hamburger {
  position: fixed;
  width: 60px;
  height: 60px;
  top: 50px;
  right: 50px;
  z-index: 20;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }

  
  .line {
    width: 40px;
    height: 5px;
    background-color: $greenish;
    position: absolute;
    // top: 100px;
    // right: 50px;
    border-radius: 20px;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%,50%);
  }

  @for $var from 1 to 4 {
    .line:nth-child(#{$var}) {
      top: #{$var * 12}px;
    }
  }
}

.hero-content{
  position: relative;
}


.widget{
  position: absolute;
  bottom: 50px;
  right: 20px;
  z-index: 5;
}


//responsiveness
@media (max-width : 850px){
  .logo{
    transform: scale(2) translateX(-30px);

  }
  #hero .social-links{
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom:10px;
    top: auto;
    left:10px;
      svg{
      margin: 10px;
    }
  }

  .content{
    // opacity: 0;
    transform: translateX(0px);
  }

  .im {
  font-family: $mainFont;
  font-size: 1.2em;
  color: $whitish;
  }  
  
  .hamburger{
    top: 15px;
    right: 10px;
    transform-origin: center;
    transform: scale(0.9);
  }

  .call-to-action {
    border: #00771063 solid 1px;
      border-radius: 2px;
      margin-top: 10px;
      font-size: 1.2em;


    &::before {
      width: 0px;
      height: 0%;
    }

    &:hover{
      color:$whitish
    }
  }

  .widget{
    bottom: 10px;
    right: 20px;
  }
}

@media (max-width :500px){
  #hero{
    justify-content: unset;
    margin-left: 30px;
  }

  .content{
    transform: unset;
    margin-top: 200px;
  }

  .heading-hero {
  font-family: $mainFont;
  width: 90vw;
    .Typewriter__wrapper, .Typewriter__cursor{
    font-size: 1em !important;
    }
  }
}

@media(max-width : 400px){
  #hero .social-links{
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom:10px;
    top: auto;
    left:10px;
      svg{
      margin: 7px;
      width: 30px;
    }
  } 
}