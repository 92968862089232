@import url('https://fonts.googleapis.com/css2?family=Gruppo&family=Ruda&display=swap');
#about-me{
    .wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;        
    }
    
    margin-top: 350px;
    margin-left: 100px;
    h1{
        font-family: 'Gruppo', cursive;
        color: #00770f;
        font-size: 8em;
        margin-bottom: 20px;
    }

    p{
        width: 40vw;
        font-family: 'Ruda', sans-serif;
        color: #989898;
        font-size: 1.2em;
        line-height: 40px;
    }

    .image{
        transform: translateY(-100px);
            margin-top: -150px;
    }
}

@media(max-width : 1520px){
    #about-me{
        margin-left: 50px;
        p{
            width: 100%;
        }
        h1{
            font-size: 6em;
        }
        grid-template-columns: 1.5fr 1fr;
        .image{
            img{
                width: 80%;
                margin-left: 50px;
                margin-top: 0px;
            }
        }
    }
}

@media(max-width : 1200px){
    #about-me{
        .image{
            img{
                margin-top: 100px;
            }
        }   
    }
}

@media(max-width : 850px){
    #about-me{
        h1{
            font-size: 3.5em;
        }
        display: block;
        .wrapper{
            display: flex;
            flex-direction: column-reverse;
            grid-template-columns: none;
        }

        .image{
            position:relative;
            z-index: 5;
            margin-top: 0px;
            img{
               width: 60vw; 
               margin-top: 50px;
            }
            
        }

        p{
            position: relative;
            z-index: 10;
            color: black;
            background: rgba(184, 184, 184, 0.842);
            width: 70vw;
            margin-right: 50px;
            margin-left: auto;
            margin-top: -100px;
            line-height: 35px;
            padding: 20px;
            border-radius: 10px;
        }

        
    }
}

@media (max-width : 500px){
    #about-me{
        h1{
            font-size: 3em;
        }
        margin-left: 20px;
        .image{
            position:relative;
            z-index: 5;
            margin-top: 0px;
            img{
               margin-left: 0px;
               margin-top: 20px;
            }
            
        }

        p{
            font-size: 1em;
            margin-right: 10px; 
            line-height: 22px;
            margin-top: -80px;

        }
    }
}