@import url("https://fonts.googleapis.com/css2?family=Ruda:wght@400;800;900&display=swap");

#skills {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  margin-top: 800px;
  h1 {
    font-size: 5em;
    font-family: "Ruda", sans-serif;
    font-weight: 900;
    margin-bottom: 20px;
  }
  p {
    width: 30vw;
    font-size: 1.2em;
    line-height: 37px;
    color: #6b6b6b;

    ol {
      margin-top: 50px;
      display: flex;
      flex-direction: row;

      li {
        margin-right: 50px;

        ul {
          padding-left: 20px;
        }
      }
    }
  }

  .skills-graphics {
    position: relative;

    img {
      position: absolute;
      top: -30%;
    }
  }
}

@media (max-width: 1520px) {
  #skills {
    grid-template-columns: 1fr 1fr;
    p{
        width: 45vw;
    }
    .skills-graphics {
      img {
        width: 100%;
        top: 10%;
      }
    }
  }
}

@media (max-width : 1200px){
    #skills{
        display: block;
        margin-left: 50px;
        .skills-graphics{
            display: none;
        }
        p{
            width: 90vw;
        }
        h1{
            color: #00770F;
        }
    }
}

@media (max-width : 500px){
  #skills{
    h1{
      font-size: 3em;
    }
    margin-left: 20px;
    p {
      margin-right: 5px;
      ol {
        padding-left: 10px;
        
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        li{
          margin-bottom: 20px;
          margin-right: 10px;
          width: min-content;
          font-size: 1em;
          ol{
            padding-left: 5px;
          }
        }
      }
    }    
  }

}