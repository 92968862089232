@font-face {
  font-family: "White Rabbit";
  src: url("../assets/fonts/WHITRABT.woff");
}

.welcome {
  color: green;
  margin: 100px;
}

.Typewriter__wrapper,
.Typewriter__cursor {
  font-size: 1.5em;
  font-family: "White Rabbit";
}

@media (max-width: 800px) {
  .welcome {
        margin-top: -15px;
    margin-bottom: 15px;
    margin-left: 15px;
    margin: 20px;
    margin-top: 50px;
  }

  .Typewriter__wrapper,
  .Typewriter__cursor {
    font-size: 1.2em;
    font-family: "White Rabbit";
  }
}
