@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

.footer{
    background: #141414;
    // box-shadow: 0px -11px 64px #00770F;
    display: flex;
    flex-direction: column;
    border-top: 4px solid #009a0073;

    .social-icons-footer{
        margin: auto;
        margin-top: 70px;
        .social-link{
            margin: 25px;
            
            svg{
                width: 35px;
            }            
        }

    }

    .copywrite{
        margin: auto;
        margin-top: 40px;
        font-family: 'Source Code Pro', monospace;
        font-size: 1.2em;

        span{
            color: green;
        }
    }

    .stay-safe{
        margin: auto;
        margin-top: 30px;
        font-family: 'Source Code Pro', monospace;
        color: #5E5E5E;
        font-size: 1.1em;
        margin-bottom: 70px;
    }
}

@media(max-width: 500px){
    .footer .social-icons-footer .social-link svg{
        width: 30px;
    }

    .footer .social-icons-footer .social-link{
        margin: 20px;
    }
}